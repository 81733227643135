import Region from "../structure/Region";
import Version from "../structure/Version";
import { RegionRepository } from "../structure/RegionRepository";

export class RegionLoader {
  public load(content: string): RegionRepository {
    let lignes = content.split("\n");
    let i = 0;
    let pos: { [nom: string]: number } = {};
    let regions: Array<Region> = new Array<Region>();
    for (let ligne of lignes) {
      let cellules = ligne.split(";");
      if (ligne === "" || cellules.length === 0) continue;
      if (i === 0) {
        //Entêtes
        cellules.forEach((value: string, index: number) => {
          pos[value] = index;
        });
      } else {
        //Data
        regions.push(
          new Region(
            parseInt(cellules[pos["id"]]),
            cellules[pos["nom"]],
            cellules[pos["dossierId"]],
            new Version(parseInt(cellules[pos["vCreation"]]), true),
            new Version(parseInt(cellules[pos["vMaj"]]), true)
          )
        );
      }
      i++;
    }
    return new RegionRepository(regions);
  }
}
