import { Component } from "react";
import { Fond, Point } from "../../../structure/PointDansLigne";
import React from "react";

interface AfficherPointProps {
  fond: Fond;
  point: Point;
  couleur: string;
}

export default class AfficherPoint extends Component<AfficherPointProps> {
  render() {
    const GaucheHaut =
      this.props.fond === Fond.GaucheHaut ||
      this.props.fond === Fond.GaucheDroiteHautCentreBas ||
      this.props.fond === Fond.GaucheDroiteHautGaucheBas ||
      this.props.fond === Fond.GaucheDroiteHautGaucheDroiteBas ||
      this.props.fond === Fond.GaucheHautGaucheDroiteBas ||
      this.props.fond === Fond.GaucheDroiteHaut ||
      this.props.fond === Fond.GaucheHautBas ||
      this.props.fond === Fond.GaucheDroiteHautDroiteBas;

    const CentreHaut = this.props.fond === Fond.CentreHaut || this.props.fond === Fond.CentreHautBas || this.props.fond === Fond.CentreHautGaucheDroiteBas;

    const DroiteHaut =
      this.props.fond === Fond.DroiteHaut ||
      this.props.fond === Fond.GaucheDroiteHautCentreBas ||
      this.props.fond === Fond.GaucheDroiteHautGaucheBas ||
      this.props.fond === Fond.GaucheDroiteHautGaucheDroiteBas ||
      this.props.fond === Fond.DroiteHautGaucheDroiteBas ||
      this.props.fond === Fond.GaucheDroiteHaut ||
      this.props.fond === Fond.DroiteHautBas ||
      this.props.fond === Fond.GaucheDroiteHautDroiteBas;

    const FourcheHaut = this.props.fond === Fond.GaucheDroiteHaut || this.props.fond === Fond.GaucheDroiteHautCentreBas;

    const GaucheBas =
      this.props.fond === Fond.GaucheBas ||
      this.props.fond === Fond.CentreHautGaucheDroiteBas ||
      this.props.fond === Fond.GaucheHautGaucheDroiteBas ||
      this.props.fond === Fond.GaucheDroiteHautGaucheDroiteBas ||
      this.props.fond === Fond.DroiteHautGaucheDroiteBas ||
      this.props.fond === Fond.GaucheDroiteBas ||
      this.props.fond === Fond.GaucheHautBas ||
      this.props.fond === Fond.GaucheDroiteHautGaucheBas;

    const CentreBas = this.props.fond === Fond.CentreBas || this.props.fond === Fond.CentreHautBas || this.props.fond === Fond.GaucheDroiteHautCentreBas;

    const DroiteBas =
      this.props.fond === Fond.DroiteBas ||
      this.props.fond === Fond.CentreHautGaucheDroiteBas ||
      this.props.fond === Fond.GaucheHautGaucheDroiteBas ||
      this.props.fond === Fond.GaucheDroiteHautGaucheDroiteBas ||
      this.props.fond === Fond.GaucheDroiteHautDroiteBas ||
      this.props.fond === Fond.GaucheDroiteBas ||
      this.props.fond === Fond.DroiteHautBas ||
      this.props.fond === Fond.DroiteHautGaucheDroiteBas;

    const FourcheBas = this.props.fond === Fond.GaucheDroiteBas || this.props.fond === Fond.CentreHautGaucheDroiteBas;

    const PointGauche = this.props.point === Point.Gauche || this.props.point === Point.GaucheSensBas || this.props.point === Point.GaucheSensHaut;
    const PointCentre = this.props.point === Point.Centre || this.props.point === Point.CentreSensBas || this.props.point === Point.CentreSensHaut;
    const PointDroite = this.props.point === Point.Droite || this.props.point === Point.DroiteSensBas || this.props.point === Point.DroiteSensHaut;
    const PointSensHaut = this.props.point === Point.GaucheSensHaut || this.props.point === Point.CentreSensHaut || this.props.point === Point.DroiteSensHaut;
    const PointSensBas = this.props.point === Point.GaucheSensBas || this.props.point === Point.CentreSensBas || this.props.point === Point.DroiteSensBas;

    const couleur = this.props.couleur === "#000000" ? "#FFFFFF" : this.props.couleur;

    return (
      <pre className="schema" style={{ color: couleur }}>
        {GaucheHaut ? "|" : " "}   {CentreHaut ? "|" : " "}   {DroiteHaut ? "|" : " "}
        <br />
        {GaucheHaut ? "|" : " "} {FourcheHaut ? "−" : " "} {CentreHaut ? "|" : " "} {FourcheHaut ? "−" : " "} {DroiteHaut ? "|" : " "}
        <br />
        {PointGauche ? "•" : " "} {PointSensHaut ? "↑" : PointSensBas ? "↓" : " "} {PointCentre ? "•" : " "} {PointSensHaut ? "↑" : PointSensBas ? "↓" : " "}{" "}
        {PointDroite ? "•" : " "}
        <br />
        {GaucheBas ? "|" : " "} {FourcheBas ? "−" : " "} {CentreBas ? "|" : " "} {FourcheBas ? "−" : " "} {DroiteBas ? "|" : " "}
        <br />
        {GaucheBas ? "|" : " "}   {CentreBas ? "|" : " "}   {DroiteBas ? "|" : " "}
      </pre>
    );
  }
}
