import GareDansLigne from "../../../structure/GareDansLigne";
import React from "react";
import { Map, Popup, TileLayer, Polyline, CircleMarker } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import "./leaflet-container.css";
import { CarteDesGaresCommunProps, CarteDesGaresCommun } from "./CarteDesGaresCommun";
import { LatLngExpression } from "leaflet";
import { Fond, Point } from "../../../structure/PointDansLigne";

interface CarteDesGaresDansLigneProps extends CarteDesGaresCommunProps {
  gares: Array<GareDansLigne>;
}

type Branche = Array<LatLngExpression>;
type Carte = { gauche: Branche | null; centre: Branche | null; droite: Branche | null };
type IdBranche = "gauche" | "centre" | "droite";

export class CarteDesGaresDansLigne extends CarteDesGaresCommun<CarteDesGaresDansLigneProps> {
  private ouvrirBranche(branches: Array<Branche>, carte: Carte, id: IdBranche, gdl: GareDansLigne) {
    carte[id] = [];
    carte[id]!.push({ lat: gdl.gare.latitude, lng: gdl.gare.longitude });
  }

  private mettreDansBranche(branches: Array<Branche>, carte: Carte, id: IdBranche, gdl: GareDansLigne) {
    if (!carte[id]) return; // Point buggé
    carte[id]!.push({ lat: gdl.gare.latitude, lng: gdl.gare.longitude });
  }

  private fermerBranche(branches: Array<Branche>, carte: Carte, id: IdBranche, gdl: GareDansLigne) {
    if (!carte[id]) return; // Point buggé
    carte[id]!.push({ lat: gdl.gare.latitude, lng: gdl.gare.longitude });
    branches.push(carte[id]!);
    carte[id] = null;
  }

  render() {
    const branches: Array<Branche> = [];
    let branchesActuelles: Carte = {
      gauche: null,
      droite: null,
      centre: null,
    };
    for (const gdl of this.props.gares) {
      if (gdl.pdlFond === Fond.Vide) {
        if (branchesActuelles.centre === null) this.ouvrirBranche(branches, branchesActuelles, "centre", gdl);
        else this.mettreDansBranche(branches, branchesActuelles, "centre", gdl);

        continue;
      }

      if (gdl.pdlFond === Fond.CentreBas) {
        this.ouvrirBranche(branches, branchesActuelles, "centre", gdl);
        continue;
      }
      if (gdl.pdlFond === Fond.CentreHautBas) {
        this.mettreDansBranche(branches, branchesActuelles, "centre", gdl);
        continue;
      }
      if (gdl.pdlFond === Fond.CentreHaut) {
        this.fermerBranche(branches, branchesActuelles, "centre", gdl);
        continue;
      }
      if (gdl.pdlFond === Fond.CentreHautGaucheDroiteBas) {
        this.fermerBranche(branches, branchesActuelles, "centre", gdl);
        this.ouvrirBranche(branches, branchesActuelles, "gauche", gdl);
        this.ouvrirBranche(branches, branchesActuelles, "droite", gdl);
        continue;
      }
      if (gdl.pdlFond === Fond.GaucheDroiteHaut) {
        this.fermerBranche(branches, branchesActuelles, "gauche", gdl);
        this.fermerBranche(branches, branchesActuelles, "droite", gdl);
        continue;
      }
      if (gdl.pdlFond === Fond.GaucheDroiteHautCentreBas) {
        this.fermerBranche(branches, branchesActuelles, "gauche", gdl);
        this.fermerBranche(branches, branchesActuelles, "droite", gdl);
        this.ouvrirBranche(branches, branchesActuelles, "centre", gdl);
        continue;
      }

      if (gdl.pdlPoint === Point.Droite || gdl.pdlPoint === Point.DroiteSensBas || gdl.pdlPoint === Point.DroiteSensHaut) {
        if (gdl.pdlFond === Fond.GaucheHautGaucheDroiteBas || gdl.pdlFond === Fond.DroiteBas) {
          this.ouvrirBranche(branches, branchesActuelles, "droite", gdl);
          continue;
        }
        if (gdl.pdlFond === Fond.GaucheDroiteHautGaucheDroiteBas || gdl.pdlFond === Fond.DroiteHautBas) {
          this.mettreDansBranche(branches, branchesActuelles, "droite", gdl);
          continue;
        }
        if (gdl.pdlFond === Fond.GaucheDroiteHautGaucheBas || gdl.pdlFond === Fond.DroiteHaut) {
          this.fermerBranche(branches, branchesActuelles, "droite", gdl);
          continue;
        }
      }

      if (gdl.pdlPoint === Point.Gauche || gdl.pdlPoint === Point.GaucheSensBas || gdl.pdlPoint === Point.GaucheSensHaut) {
        if (gdl.pdlFond === Fond.DroiteHautGaucheDroiteBas || gdl.pdlFond === Fond.GaucheBas) {
          this.ouvrirBranche(branches, branchesActuelles, "gauche", gdl);
          continue;
        }
        if (gdl.pdlFond === Fond.GaucheDroiteHautGaucheDroiteBas || gdl.pdlFond === Fond.GaucheHautBas) {
          this.mettreDansBranche(branches, branchesActuelles, "gauche", gdl);
          continue;
        }
        if (gdl.pdlFond === Fond.GaucheDroiteHautDroiteBas || gdl.pdlFond === Fond.GaucheHaut) {
          this.fermerBranche(branches, branchesActuelles, "gauche", gdl);
          continue;
        }
      }

      // Cas non gérés
      if (!branchesActuelles.centre) branchesActuelles.centre = [];
      this.mettreDansBranche(branches, branchesActuelles, "centre", gdl);
    }

    if (branchesActuelles.centre) branches.push(branchesActuelles.centre);

    // const branches = [this.props.gares.map((gdl: GareDansLigne): [number, number] => [gdl.gare.latitude, gdl.gare.longitude])];
    const couleurLigne = this.props.gares[0].ligne.couleur;
    console.log("couleur ligne", couleurLigne);
    return (
      <div className="leaflet-container">
        <Map
          center={
            this.props.gares
              .filter((gdl: GareDansLigne) => !gdl.isDeleted())
              .map((gdl: GareDansLigne): [number, number] => [gdl.gare.latitude, gdl.gare.longitude])
              .reduce((acc, item) => [acc[0] + item[0], acc[1] + item[1]])
              .map((value: number) => value / this.props.gares.length) as [number, number]
          }
          zoom={12}
        >
          <TileLayer
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
          />
          {branches.map((branche) => (
            <Polyline positions={branche} color={couleurLigne} opacity={0.6} weight={8} />
          ))}
          {this.props.gares
            .filter((gdl: GareDansLigne) => !gdl.isDeleted())
            .map((gdl: GareDansLigne) => {
              let gare = gdl.gare;
              let position: [number, number] = [gare.latitude, gare.longitude];
              return (
                <CircleMarker key={gare.idExterne} radius={4} center={position} color={couleurLigne} fill={true} fillColor={"white"} fillOpacity={1} weight={2}>
                  <Popup>
                    <b>Nom</b>
                    <br />
                    {gare.nom}
                    <br />
                    <b>Ordre</b>
                    <br />
                    {gdl.ordre}
                    <br />
                    <b>Point</b>
                    <br />
                    {Fond[gdl.pdlFond]} − {Point[gdl.pdlPoint]}
                  </Popup>
                </CircleMarker>
              );
            })}
        </Map>
      </div>
    );
  }
}
